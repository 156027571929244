import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGetAuthenticatedUserQuery } from "@bespeak/apollo";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import { Scope, usePermissionsStore } from "@/store/permissions";
import Button from "@/components/base/atoms/Button";
import NavigationUserMenu from "@/components/base/molecules/navigation-user-menu/navigation-user-menu";
import NavigationBarLeft from "@/components/base/molecules/navigation-bar-left/navigation-bar-left";
import {
    HomeLineIcon,
    SearchSmIcon,
    XCloseIcon,
    Users02Icon,
    PresentationChart02,
    LogOut01,
} from "@/components/base/icons";

const NavigationBar = () => {
    const { t } = useTranslation("common", { keyPrefix: "main-menu" });
    const { hasPermission } = usePermissionsStore();
    const { tenantHasSearchKey, handleToggleSearchActive, searchActive } =
        useContext(AlgoliaSearchContext) ?? {};
    const { data } = useGetAuthenticatedUserQuery();

    const hasSufficientGroupPermissions = [
        hasPermission(Scope.GROUPS_CREATE),
        hasPermission(Scope.GROUPS_UPDATE),
    ].includes(true);

    const user = data?.getAuthenticatedUser;

    const userMenus = [
        [
            ...(hasSufficientGroupPermissions
                ? [
                      {
                          icon: <Users02Icon className="h-5 w-5 shrink-0" />,
                          label: t("user-menu.groups"),
                          href: "/groups",
                      },
                  ]
                : []),
            ...(hasPermission(Scope.GROUP_PROGRESS_READ)
                ? [
                      {
                          icon: (
                              <PresentationChart02 className="h-5 w-5 shrink-0" />
                          ),
                          label: t("user-menu.rapportage"),
                          href: "/rapportage",
                      },
                  ]
                : []),
            {
                icon: <HomeLineIcon className="h-5 w-5 shrink-0" />,
                label: t("user-menu.products"),
                href: "/profile/products",
            },
        ],
        [
            {
                icon: <LogOut01 className="h-5 w-5 shrink-0" />,
                label: t("user-menu.log-out"),
                href: "/logout",
            },
        ],
    ];

    return (
        <section className="sticky top-0 z-[200] bg-white">
            <div className="container mx-auto flex items-center justify-between px-8 pb-4 pt-6">
                <NavigationBarLeft />
                <div className="flex items-center gap-4">
                    {tenantHasSearchKey ? (
                        <>
                            <div>
                                <Button
                                    variant="primaryGhost"
                                    size="small"
                                    iconOnly
                                    onClick={handleToggleSearchActive}
                                >
                                    {searchActive ? (
                                        <XCloseIcon className="h-5 w-5" />
                                    ) : (
                                        <SearchSmIcon className="h-5 w-5" />
                                    )}
                                </Button>
                            </div>
                            <div className="border-outline-300 h-11 border-r"></div>
                        </>
                    ) : null}
                    {user && (
                        <>
                            <NavigationUserMenu user={user} menus={userMenus} />
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};
export default NavigationBar;
