export type PossibleTypesResultData = {
    possibleTypes: {
        AnswerCorrectness: [
            "MpcAnswerCorrectness",
            "PollAnswerCorrectness",
            "QuestionConnectAnswerCorrectness",
            "SortQuestionAnswerCorrectness",
            "StandAnswerCorrectness",
        ];
        AppBuildingBlockDto: [
            "AppCarouselBuildingBlockDto",
            "AppContentBuildingBlockDto",
            "AppConversationBuildingBlockDto",
            "AppHotspotBuildingBlockDto",
            "AppInteractiveQuestionBuildingBlockDto",
            "AppLinkBuildingBlockDto",
            "AppMoodboardBuildingBlockDto",
            "AppMpcBuildingBlockDto",
            "AppPollBuildingBlockDto",
            "AppQuestionConnectBuildingBlockDto",
            "AppQuoteBuildingBlockDto",
            "AppReferenceCardCarouselBuildingBlockDto",
            "AppSceneBuildingBlockDto",
            "AppSortQuestionBuildingBlockDto",
            "AppStandBuildingBlockDto",
        ];
        AssetDto: ["ExternalVideoAssetDto", "FileAssetDto"];
        BaseBuildingBlock: [
            "AppCarouselBuildingBlockDto",
            "AppContentBuildingBlockDto",
            "AppConversationBuildingBlockDto",
            "AppHotspotBuildingBlockDto",
            "AppInteractiveQuestionBuildingBlockDto",
            "AppLinkBuildingBlockDto",
            "AppMoodboardBuildingBlockDto",
            "AppMpcBuildingBlockDto",
            "AppPollBuildingBlockDto",
            "AppQuestionConnectBuildingBlockDto",
            "AppQuoteBuildingBlockDto",
            "AppReferenceCardCarouselBuildingBlockDto",
            "AppSceneBuildingBlockDto",
            "AppSortQuestionBuildingBlockDto",
            "AppStandBuildingBlockDto",
            "CarouselBuildingBlockDto",
            "ContentBuildingBlockDto",
            "ConversationBuildingBlockDto",
            "HotspotBuildingBlockDto",
            "InteractiveQuestionBuildingBlockDto",
            "LinkBuildingBlockDto",
            "MoodboardBuildingBlockDto",
            "MpcBuildingBlockDto",
            "PollBuildingBlockDto",
            "QuestionConnectBuildingBlockDto",
            "QuoteBuildingBlockDto",
            "ReferenceCardsCarouselBuildingBlockDto",
            "SceneBuildingBlockDto",
            "SortQuestionBuildingBlockDto",
            "StandBuildingBlockDto",
        ];
        BuildingBlockDto: [
            "CarouselBuildingBlockDto",
            "ContentBuildingBlockDto",
            "ConversationBuildingBlockDto",
            "HotspotBuildingBlockDto",
            "InteractiveQuestionBuildingBlockDto",
            "LinkBuildingBlockDto",
            "MoodboardBuildingBlockDto",
            "MpcBuildingBlockDto",
            "PollBuildingBlockDto",
            "QuestionConnectBuildingBlockDto",
            "QuoteBuildingBlockDto",
            "ReferenceCardsCarouselBuildingBlockDto",
            "SceneBuildingBlockDto",
            "SortQuestionBuildingBlockDto",
            "StandBuildingBlockDto",
        ];
        BuildingBlocksLabel: ["BuildingBlocksLabelDto"];
        CarouselBuildingBlock: [
            "AppCarouselBuildingBlockDto",
            "CarouselBuildingBlockDto",
        ];
        ContentBlockList: ["AppContentBlockListDto", "ContentBlockListDto"];
        ContentBuildingBlock: [
            "AppContentBuildingBlockDto",
            "ContentBuildingBlockDto",
        ];
        ContentListItem: [
            "AppContentBlockListItemDto",
            "ContentBlockListItemDto",
        ];
        ConversationBuildingBlock: [
            "AppConversationBuildingBlockDto",
            "ConversationBuildingBlockDto",
        ];
        HotspotBuildingBlock: [
            "AppHotspotBuildingBlockDto",
            "HotspotBuildingBlockDto",
        ];
        InteractiveBuildingBlock: [
            "MpcAnswerCorrectness",
            "MpcBuildingBlockDto",
            "PollAnswerCorrectness",
            "PollBuildingBlockDto",
            "QuestionConnectAnswerCorrectness",
            "QuestionConnectBuildingBlockDto",
            "SortQuestionAnswerCorrectness",
            "SortQuestionBuildingBlockDto",
            "StandAnswerCorrectness",
            "StandBuildingBlockDto",
        ];
        InteractiveBuildingBlockWithHelpText: [
            "MpcAnswerCorrectness",
            "MpcBuildingBlockDto",
        ];
        InteractiveQuestionBuildingBlock: [
            "AppInteractiveQuestionBuildingBlockDto",
            "InteractiveQuestionBuildingBlockDto",
        ];
        LinkBuildingBlock: ["AppLinkBuildingBlockDto", "LinkBuildingBlockDto"];
        Message: ["AppMessageDto", "MessageDto"];
        MoodboardBuildingBlock: [
            "AppMoodboardBuildingBlockDto",
            "MoodboardBuildingBlockDto",
        ];
        MoodboardImage: ["MoodboardImageDto"];
        MpcAnswer: ["AnswerDto", "AppAnswerDto"];
        MpcBuildingBlock: ["AppMpcBuildingBlockDto", "MpcBuildingBlockDto"];
        Person: ["AppPersonDto", "PersonDto"];
        PollAnswer: ["PollAnswerDto"];
        PollBuildingBlock: ["AppPollBuildingBlockDto", "PollBuildingBlockDto"];
        ProgressDto: [
            "DidacticToolProgressDto",
            "EducationalPathProgressDto",
            "ModuleProgressDto",
        ];
        QuestionConnectBuildingBlock: [
            "AppQuestionConnectBuildingBlockDto",
            "QuestionConnectBuildingBlockDto",
        ];
        QuestionConnectItem: [
            "AppQuestionConnectItemDto",
            "QuestionConnectItemAnswerDto",
            "QuestionConnectItemDto",
        ];
        QuoteBuildingBlock: [
            "AppQuoteBuildingBlockDto",
            "QuoteBuildingBlockDto",
        ];
        ReferenceCardLabelDto: [
            "ReferenceCardCategoryDto",
            "ReferenceCardLeadDto",
        ];
        ReferenceCardsCarouselBuildingBlock: [
            "AppReferenceCardCarouselBuildingBlockDto",
            "ReferenceCardsCarouselBuildingBlockDto",
        ];
        SceneBuildingBlock: [
            "AppSceneBuildingBlockDto",
            "SceneBuildingBlockDto",
        ];
        SortQuestionAnswer: [
            "AppSortQuestionAnswerDto",
            "SortQuestionAnswerDto",
        ];
        SortQuestionBuildingBlock: [
            "AppSortQuestionBuildingBlockDto",
            "SortQuestionBuildingBlockDto",
        ];
        Spot: ["SpotDto"];
        StandBuildingBlock: [
            "AppStandBuildingBlockDto",
            "StandBuildingBlockDto",
        ];
    };
};
const result: PossibleTypesResultData = {
    possibleTypes: {
        AnswerCorrectness: [
            "MpcAnswerCorrectness",
            "PollAnswerCorrectness",
            "QuestionConnectAnswerCorrectness",
            "SortQuestionAnswerCorrectness",
            "StandAnswerCorrectness",
        ],
        AppBuildingBlockDto: [
            "AppCarouselBuildingBlockDto",
            "AppContentBuildingBlockDto",
            "AppConversationBuildingBlockDto",
            "AppHotspotBuildingBlockDto",
            "AppInteractiveQuestionBuildingBlockDto",
            "AppLinkBuildingBlockDto",
            "AppMoodboardBuildingBlockDto",
            "AppMpcBuildingBlockDto",
            "AppPollBuildingBlockDto",
            "AppQuestionConnectBuildingBlockDto",
            "AppQuoteBuildingBlockDto",
            "AppReferenceCardCarouselBuildingBlockDto",
            "AppSceneBuildingBlockDto",
            "AppSortQuestionBuildingBlockDto",
            "AppStandBuildingBlockDto",
        ],
        AssetDto: ["ExternalVideoAssetDto", "FileAssetDto"],
        BaseBuildingBlock: [
            "AppCarouselBuildingBlockDto",
            "AppContentBuildingBlockDto",
            "AppConversationBuildingBlockDto",
            "AppHotspotBuildingBlockDto",
            "AppInteractiveQuestionBuildingBlockDto",
            "AppLinkBuildingBlockDto",
            "AppMoodboardBuildingBlockDto",
            "AppMpcBuildingBlockDto",
            "AppPollBuildingBlockDto",
            "AppQuestionConnectBuildingBlockDto",
            "AppQuoteBuildingBlockDto",
            "AppReferenceCardCarouselBuildingBlockDto",
            "AppSceneBuildingBlockDto",
            "AppSortQuestionBuildingBlockDto",
            "AppStandBuildingBlockDto",
            "CarouselBuildingBlockDto",
            "ContentBuildingBlockDto",
            "ConversationBuildingBlockDto",
            "HotspotBuildingBlockDto",
            "InteractiveQuestionBuildingBlockDto",
            "LinkBuildingBlockDto",
            "MoodboardBuildingBlockDto",
            "MpcBuildingBlockDto",
            "PollBuildingBlockDto",
            "QuestionConnectBuildingBlockDto",
            "QuoteBuildingBlockDto",
            "ReferenceCardsCarouselBuildingBlockDto",
            "SceneBuildingBlockDto",
            "SortQuestionBuildingBlockDto",
            "StandBuildingBlockDto",
        ],
        BuildingBlockDto: [
            "CarouselBuildingBlockDto",
            "ContentBuildingBlockDto",
            "ConversationBuildingBlockDto",
            "HotspotBuildingBlockDto",
            "InteractiveQuestionBuildingBlockDto",
            "LinkBuildingBlockDto",
            "MoodboardBuildingBlockDto",
            "MpcBuildingBlockDto",
            "PollBuildingBlockDto",
            "QuestionConnectBuildingBlockDto",
            "QuoteBuildingBlockDto",
            "ReferenceCardsCarouselBuildingBlockDto",
            "SceneBuildingBlockDto",
            "SortQuestionBuildingBlockDto",
            "StandBuildingBlockDto",
        ],
        BuildingBlocksLabel: ["BuildingBlocksLabelDto"],
        CarouselBuildingBlock: [
            "AppCarouselBuildingBlockDto",
            "CarouselBuildingBlockDto",
        ],
        ContentBlockList: ["AppContentBlockListDto", "ContentBlockListDto"],
        ContentBuildingBlock: [
            "AppContentBuildingBlockDto",
            "ContentBuildingBlockDto",
        ],
        ContentListItem: [
            "AppContentBlockListItemDto",
            "ContentBlockListItemDto",
        ],
        ConversationBuildingBlock: [
            "AppConversationBuildingBlockDto",
            "ConversationBuildingBlockDto",
        ],
        HotspotBuildingBlock: [
            "AppHotspotBuildingBlockDto",
            "HotspotBuildingBlockDto",
        ],
        InteractiveBuildingBlock: [
            "MpcAnswerCorrectness",
            "MpcBuildingBlockDto",
            "PollAnswerCorrectness",
            "PollBuildingBlockDto",
            "QuestionConnectAnswerCorrectness",
            "QuestionConnectBuildingBlockDto",
            "SortQuestionAnswerCorrectness",
            "SortQuestionBuildingBlockDto",
            "StandAnswerCorrectness",
            "StandBuildingBlockDto",
        ],
        InteractiveBuildingBlockWithHelpText: [
            "MpcAnswerCorrectness",
            "MpcBuildingBlockDto",
        ],
        InteractiveQuestionBuildingBlock: [
            "AppInteractiveQuestionBuildingBlockDto",
            "InteractiveQuestionBuildingBlockDto",
        ],
        LinkBuildingBlock: ["AppLinkBuildingBlockDto", "LinkBuildingBlockDto"],
        Message: ["AppMessageDto", "MessageDto"],
        MoodboardBuildingBlock: [
            "AppMoodboardBuildingBlockDto",
            "MoodboardBuildingBlockDto",
        ],
        MoodboardImage: ["MoodboardImageDto"],
        MpcAnswer: ["AnswerDto", "AppAnswerDto"],
        MpcBuildingBlock: ["AppMpcBuildingBlockDto", "MpcBuildingBlockDto"],
        Person: ["AppPersonDto", "PersonDto"],
        PollAnswer: ["PollAnswerDto"],
        PollBuildingBlock: ["AppPollBuildingBlockDto", "PollBuildingBlockDto"],
        ProgressDto: [
            "DidacticToolProgressDto",
            "EducationalPathProgressDto",
            "ModuleProgressDto",
        ],
        QuestionConnectBuildingBlock: [
            "AppQuestionConnectBuildingBlockDto",
            "QuestionConnectBuildingBlockDto",
        ],
        QuestionConnectItem: [
            "AppQuestionConnectItemDto",
            "QuestionConnectItemAnswerDto",
            "QuestionConnectItemDto",
        ],
        QuoteBuildingBlock: [
            "AppQuoteBuildingBlockDto",
            "QuoteBuildingBlockDto",
        ],
        ReferenceCardLabelDto: [
            "ReferenceCardCategoryDto",
            "ReferenceCardLeadDto",
        ],
        ReferenceCardsCarouselBuildingBlock: [
            "AppReferenceCardCarouselBuildingBlockDto",
            "ReferenceCardsCarouselBuildingBlockDto",
        ],
        SceneBuildingBlock: [
            "AppSceneBuildingBlockDto",
            "SceneBuildingBlockDto",
        ],
        SortQuestionAnswer: [
            "AppSortQuestionAnswerDto",
            "SortQuestionAnswerDto",
        ],
        SortQuestionBuildingBlock: [
            "AppSortQuestionBuildingBlockDto",
            "SortQuestionBuildingBlockDto",
        ],
        Spot: ["SpotDto"],
        StandBuildingBlock: [
            "AppStandBuildingBlockDto",
            "StandBuildingBlockDto",
        ],
    },
};
export default result;
