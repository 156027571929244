import type { SearchResult } from "./SearchResult.component";
import css from "./SearchResult.module.css";
import { NavigateToSearchResult } from "@/lib/navigateToSearchResult/navigateToSearchResult";
import { useNavigate } from "react-router-dom";
import { useProgramStore } from "@/store/preferences";
import { useReferenceCardDialog } from "@/components/organisms/ReferenceCardDialog/useReferenceCardDialog";

export const SearchResultMain = ({
    linkToIds,
    children,
    type,
}: SearchResult.Main) => {
    const navigate = useNavigate();
    const { value: currentProgram } = useProgramStore();

    const referenceCardDialog = useReferenceCardDialog({
        value: {
            programId: linkToIds?.educational_program_id || "",
        },
    });

    const handleNavigate = () => {
        const mappedSearch = {
            educational_program: currentProgram.toString(),
            didactic_tool: linkToIds.didactic_tool_id,
            module: linkToIds.module_id,
            educational_path: linkToIds.educational_path_id,
        };

        const linkToId = NavigateToSearchResult(mappedSearch);

        if (linkToId) {
            return navigate(linkToId);
        }
    };

    const handleRefCardModal = () => {
        if (linkToIds?.reference_card_id) {
            referenceCardDialog?.open(linkToIds?.reference_card_id);
            document.body.style.overflow = "hidden";
        }
    };

    const handleOnClick = () => {
        if (type === "reference-card") {
            return handleRefCardModal();
        }

        return handleNavigate();
    };

    return (
        <button onClick={handleOnClick} className={css.searchResult}>
            {children}
        </button>
    );
};
